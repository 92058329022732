import React, { useState, Fragment } from "react";

import Sidebar from "../blocks/Sidebar";
import Button from "./Button";
import P from "../elements/P";
import { ReactComponent as DashboardIcon } from "../assets/img/dashboard.svg";
import { ReactComponent as BalanceIcon } from "../assets/img/balance-icon.svg";
import { ReactComponent as PaymentIcon } from "../assets/img/payment-icon.svg";
import { ReactComponent as TransferIcon } from "../assets/img/transfer-icon.svg";
import { ReactComponent as DeveloperIcon } from "../assets/img/developer-icon.svg";
import { ReactComponent as SettingsIcon } from "../assets/img/settings-icon.svg";
import { ReactComponent as AddIcon } from "../assets/img/add-icon.svg";
import BusinessDropDown from "./BusinessDropdown";
import AcceptPaymentModal from "pages/Payments/components/AcceptPaymentModal";

const routes = [
  {
    link: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    exact: true
  },
  {
    link: "/dashboard/balances",
    name: "Balances",
    icon: BalanceIcon,
    exact: true
  },
  {
    link: "/dashboard/payments",
    name: "Payments",
    icon: PaymentIcon,
    exact: true
  },
  {
    link: "/dashboard/transfers",
    name: "Transfers",
    icon: TransferIcon,
    exact: true
  },
  {
    link: "/dashboard/developers",
    name: "Developers",
    icon: DeveloperIcon,
    exact: false
  },
  {
    link: "/dashboard/settings",
    name: "Settings",
    icon: SettingsIcon,
    exact: false
  }
];

function SideNav(props) {
  const [showAcceptPaymentModal, toggleshowAcceptPaymentModal] = useState(
    false
  );
  return (
    <Fragment>
      <Sidebar className={props.show && "show"}>
        <Sidebar.Section>
          <BusinessDropDown />
        </Sidebar.Section>
        <Sidebar.Section links>
          {routes.map(({ link, icon: Icon, name, exact }) => (
            <Sidebar.Link key={link} to={link} exact={exact}>
              <Icon />
              <P small semiBold>
                {name}
              </P>
            </Sidebar.Link>
          ))}
        </Sidebar.Section>
        <Sidebar.Section>
          <Button
            light
            fullWidth
            icon={AddIcon}
            text="Accept Payments"
            onClick={() =>
              toggleshowAcceptPaymentModal(!showAcceptPaymentModal)
            }
          />
        </Sidebar.Section>
      </Sidebar>
      <AcceptPaymentModal
        open={showAcceptPaymentModal}
        onClose={toggleshowAcceptPaymentModal}
      />
    </Fragment>
  );
}

export default SideNav;
