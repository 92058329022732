import mapKeys from "lodash/mapKeys";

import {
  FETCH_BUSINESSES,
  SET_ACTIVE_BUSINESS,
  CREATE_BUSINESS,
  UPDATE_BUSINESS_EMAIL_PREFERENCE,
  UPDATE_BUSINESS_AUTO_CONVERT,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_ALWAYS_CONVERT,
  GET_BUSINESS
} from "./actionTypes";
import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  businesses: null,
  activeBusiness: null,
  fetching: false,
  error: null,
  createBusiness: {
    fetching: false,
    error: null
  },
  updatingBusiness: {
    email_notification: { fetching: false, error: null },
    autoConvert: { fetching: false, error: null }
  },
  profile: { updating: false, error: null },
  alwaysConvert: { updating: false, error: null },
  business: { fetching: false, error: null }
};

export default function business(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;

    // FETCH_BUSINESSES
    case FETCH_BUSINESSES.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_BUSINESSES.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_BUSINESSES.fulfilled:
      return {
        ...state,
        fetching: false,
        businesses: mapKeys(action.payload.data, _b => _b.id) // action.payload.data
      };

    // FETCH_BUSINESS
    case GET_BUSINESS.pending:
      return {
        ...state,
        business: {
          error: null,
          fetching: true
        }
      };
    case GET_BUSINESS.rejected:
      return {
        ...state,
        business: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case GET_BUSINESS.fulfilled:
      return {
        ...state,
        business: {
          fetching: false,
          error: null
        },
        businesses: {
          ...state.businesses,
          [action.payload.data.id]: action.payload.data
        }
      };
    case SET_ACTIVE_BUSINESS.fulfilled:
      return {
        ...state,
        activeBusiness: action.payload
      };
    case CREATE_BUSINESS.pending:
      return {
        ...state,
        createBusiness: {
          ...state.createBusiness,
          fetching: true,
          error: null
        }
      };
    case CREATE_BUSINESS.rejected:
      return {
        ...state,
        createBusiness: {
          ...state.createBusiness,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case CREATE_BUSINESS.fulfilled:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [action.payload.data.id]: action.payload.data
        },
        activeBusiness: action.payload.data.id,
        createBusiness: {
          ...state.createBusiness,
          fetching: false,
          error: parseError(action.payload)
        }
      };

    // UPDATE_BUSINESS_EMAIL_PREFERENCE
    case UPDATE_BUSINESS_EMAIL_PREFERENCE.pending:
      return {
        ...state,
        updatingBusiness: {
          ...state.updatingBusiness,
          email_notification: { fetching: true, error: null }
        }
      };
    case UPDATE_BUSINESS_EMAIL_PREFERENCE.rejected:
      return {
        ...state,
        updatingBusiness: {
          ...state.updatingBusiness,
          email_notification: {
            fetching: false,
            error: parseError(action.payload)
          }
        }
      };
    case UPDATE_BUSINESS_EMAIL_PREFERENCE.fulfilled:
      const activeBusiness = state.activeBusiness;
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [activeBusiness]: {
            ...state.businesses[activeBusiness],
            preference: {
              ...state.businesses[activeBusiness].preference,
              email_notification: !state.businesses[activeBusiness].preference
                .email_notification
            }
          }
        },
        updatingBusiness: {
          ...state.updatingBusiness,
          email_notification: { fetching: false, error: null }
        }
      };

    // UPDATE_BUSINESS_AUTO_CONVERT
    case UPDATE_BUSINESS_AUTO_CONVERT.pending:
      return {
        ...state,
        updatingBusiness: {
          ...state.updatingBusiness,
          autoConvert: { fetching: true, error: null }
        }
      };
    case UPDATE_BUSINESS_AUTO_CONVERT.rejected:
      return {
        ...state,
        updatingBusiness: {
          ...state.updatingBusiness,
          autoConvert: {
            fetching: false,
            error: parseError(action.payload)
          }
        }
      };
    case UPDATE_BUSINESS_AUTO_CONVERT.fulfilled: {
      const activeBusiness = state.activeBusiness;
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [activeBusiness]: {
            ...state.businesses[activeBusiness],
            preference: {
              ...state.businesses[activeBusiness].preference,
              auto_convert: !state.businesses[activeBusiness].preference
                .auto_convert
            }
          }
        },
        updatingBusiness: {
          ...state.updatingBusiness,
          autoConvert: { fetching: false, error: null }
        }
      };
    }

    // UPDATE_BUSINESS
    case UPDATE_BUSINESS.pending:
      return {
        ...state,
        profile: {
          ...state.profile,
          updating: true,
          error: null
        }
      };
    case UPDATE_BUSINESS.rejected:
      return {
        ...state,
        profile: {
          ...state.profile,
          updating: false,
          error: parseError(action.payload)
        }
      };
    case UPDATE_BUSINESS.fulfilled: {
      const activeBusiness = state.activeBusiness;
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [activeBusiness]: {
            ...state.businesses[activeBusiness]
          }
        },
        profile: {
          ...state.profile,
          updating: false,
          error: null
        }
      };
    }

    // UPDATE_BUSINESS_ALWAYS_CONVERT
    case UPDATE_BUSINESS_ALWAYS_CONVERT.pending:
      return {
        ...state,
        alwaysConvert: {
          ...state.alwaysConvert,
          updating: true,
          error: null
        }
      };
    case UPDATE_BUSINESS_ALWAYS_CONVERT.rejected:
      return {
        ...state,
        alwaysConvert: {
          ...state.alwaysConvert,
          updating: false,
          error: parseError(action.payload)
        }
      };
    case UPDATE_BUSINESS_ALWAYS_CONVERT.fulfilled: {
      const activeBusiness = state.activeBusiness;
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [activeBusiness]: {
            ...state.businesses[activeBusiness],
            preference: {
              ...state.businesses[activeBusiness].preference,
              always_convert: !state.businesses[activeBusiness].preference
                .always_convert
            }
          }
        },
        alwaysConvert: {
          ...state.alwaysConvert,
          updating: false,
          error: null
        }
      };
    }
    default:
      return state;
  }
}
