import styled from "styled-components";
import Sidebar from "./Sidebar";
import Main from "./Main";

const Layout = styled.main`
  height: 100%;
  width: 100%;
  display: flex;
`;

Layout.Sidebar = Sidebar;
Layout.Main = Main;

export default Layout;
