import styled from "styled-components";
import Icon from "./Icon";
import DropDown from "./Dropdown";
import Info from "./Info";
import Item from "./Item";
import Seperator from "./Seperator";

const ProfileDropdown = styled.div`
  position: relative;
`;

ProfileDropdown.Icon = Icon;
ProfileDropdown.Info = Info;
ProfileDropdown.Dropdown = DropDown;
ProfileDropdown.Item = Item;
ProfileDropdown.Seperator = Seperator;

export default ProfileDropdown;
