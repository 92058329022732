import styled from "styled-components";

export default styled.header`
  /* height: 72px; */
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  & > button.close-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: none;
    }
    & > svg {
      height: 15px;
      width: 15px;
    }
  }
`;
