import styled from "styled-components";

export default styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: flex-start;
  font-family: "CharlieDisplay", sans-serif;
  width: 100%;
  padding: 10px 0px;
  cursor: pointer;
  background-color: transparent;
  color: #67c462;
  & > p {
    margin-left: 8px;
  }

  & > svg use {
    fill: #67c462;
  }
`;
