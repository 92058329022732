import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import Card from "blocks/Card";
import DashboardHeader from "blocks/DashboardHeader";
import FormInput from "blocks/FormInput";
import CentererdContentAndText from "blocks/CentererdContentAndText";
import H2 from "elements/H2";
import P from "elements/P";
import { ReactComponent as DownloadIcon } from "assets/img/download-icon.svg";

import Button from "components/Button";
import ChartWrapper from "containers/ChartWrapper";
import Area from "components/Chart/Area";
import Loader from "components/Loader";

import {
  getTodayDate,
  getYesterdayDate,
  getStartOfWeek,
  getStartOfLast30Days,
  dateToYearMonthAndDayformat,
  dateToMonthStringDayAndYearformat
} from "utils/date";
import getTotalPriceFromAnalyticsPoints from "utils/getTotalPriceFromAnalyticsPoints";
import { formatNumberToMoney } from "utils/money";

import {
  getPaymentAnalytics,
  getTransferAnalytics
} from "redux/analytics/actions";

const todayDate = getTodayDate();
const yesterdayDate = getYesterdayDate();

const peroidOptions = {
  today: {
    startDate: todayDate,
    endDate: todayDate
  },
  yesterday: {
    startDate: yesterdayDate,
    endDate: yesterdayDate
  },
  "this week": {
    startDate: getStartOfWeek(todayDate),
    endDate: todayDate
  },
  "last 30 days": {
    startDate: getStartOfLast30Days(todayDate),
    endDate: todayDate
  }
};

function Dashboard({
  analytics,
  getPaymentAnalytics,
  getTransferAnalytics,
  business
}) {
  const [peroid, setPeroid] = useState("today");
  const [analyticsType, setAnalyticsType] = useState("payments");

  function onAnalyticsTypeChange(event) {
    setAnalyticsType(event.target.value);
  }

  function onPeroidChange(event) {
    setPeroid(event.target.value);
  }

  useEffect(() => {
    if (analyticsType === "payments") {
      getPaymentAnalytics(
        dateToYearMonthAndDayformat(peroidOptions[peroid].startDate),
        dateToYearMonthAndDayformat(peroidOptions[peroid].endDate)
      );
    } else if (analyticsType === "transfers") {
      getTransferAnalytics(
        dateToYearMonthAndDayformat(peroidOptions[peroid].startDate),
        dateToYearMonthAndDayformat(peroidOptions[peroid].endDate)
      );
    }
  }, [
    getPaymentAnalytics,
    getTransferAnalytics,
    peroid,
    analyticsType,
    business.activeBusiness
  ]);

  const {
    data: { currency_code, points },
    fetching
  } = analytics[analyticsType];

  const totalPrice = getTotalPriceFromAnalyticsPoints(points);

  const priceMoneyValue = formatNumberToMoney({
    number: totalPrice,
    currencyCode: currency_code
  });

  const { startDate, endDate } = peroidOptions[peroid];
  const dateTodisplay =
    startDate === endDate
      ? dateToMonthStringDayAndYearformat(startDate)
      : `${dateToMonthStringDayAndYearformat(
          startDate
        )} - ${dateToMonthStringDayAndYearformat(endDate)}`;

  return (
    <Card elevated>
      <Card.Header>
        <DashboardHeader>
          <div className="selectors">
            <FormInput.Select
              className="wrap bold-text--600"
              onChange={onAnalyticsTypeChange}
              dark
            >
              <option value="payments">Showing Payments</option>
              <option value="transfers">Showing Transfers</option>
            </FormInput.Select>
            <FormInput.Select
              className="wrap bold-text--600"
              onChange={onPeroidChange}
              transparent
            >
              {Object.keys(peroidOptions).map((peroid, index) => (
                <option key={index} value={peroid}>
                  {`For ${peroid}`}
                </option>
              ))}
            </FormInput.Select>
          </div>
          <div className="download">
            <Button dark={true} icon={DownloadIcon} text="Download summary" />
          </div>
        </DashboardHeader>
      </Card.Header>
      <Card.Body>
        {fetching ? (
          <Loader />
        ) : (
          <Fragment>
            <CentererdContentAndText>
              {totalPrice > 0 ? (
                <Fragment>
                  <H2>{priceMoneyValue}</H2>
                  <P small>{dateTodisplay}</P>
                </Fragment>
              ) : (
                <P>
                  You currently have no payment data.
                  <br /> Once you do, this is where you’ll view them.
                </P>
              )}
            </CentererdContentAndText>
            <ChartWrapper
              chart={options => (
                <Area
                  options={options}
                  data={points}
                  name={analyticsType}
                  currencyCode={currency_code}
                />
              )}
            />
          </Fragment>
        )}
      </Card.Body>
    </Card>
  );
}

function mapStateToProps({ analytics, business }) {
  return {
    analytics,
    business
  };
}

export default connect(mapStateToProps, {
  getPaymentAnalytics,
  getTransferAnalytics
})(Dashboard);
