import { format, subDays, startOfWeek } from "date-fns";

function getTodayDate() {
  const todaydate = new Date().toString();
  return todaydate;
}

function getYesterdayDate() {
  const todaydate = new Date();
  const yesterdayDate = subDays(new Date(todaydate), 1);
  return yesterdayDate;
}

function getStartOfWeek(startDate) {
  const weekStart = startOfWeek(new Date(startDate), { weekStartsOn: 1 });
  return weekStart;
}

function getStartOfLast30Days(startDate) {
  const firstDateOfLast30Days = subDays(new Date(startDate), 30);
  return firstDateOfLast30Days;
}

function dateToYearMonthAndDayformat(date) {
  return format(new Date(date), "yyyy-MM-dd");
}

function dateToMonthStringDayAndYearformat(date) {
  return format(new Date(date), "LLL dd, yyy");
}

export {
  getTodayDate,
  getYesterdayDate,
  getStartOfWeek,
  getStartOfLast30Days,
  dateToYearMonthAndDayformat,
  dateToMonthStringDayAndYearformat
};
