import React, { useState } from "react";

import { connect } from "react-redux";

import { generatePaymentLink, clearPaymentlink } from "redux/payments/actions";
import { formatNumberWithComma, formatStrToNumber } from "utils/number";

import Modal from "components/Modal";
import Copiable from "components/Copiable";
import Button from "components/Button";
import SpacedVerticalItems from "blocks/SpacedVerticalItems";
import P from "elements/P";

import AcceptPaymentForm from "./AcceptPaymentForm";

const initialFormState = {
  description: "",
  amount: ""
};

function AcceptPaymentModal({
  open,
  onClose,
  currency,
  fetchingPaymentLink,
  paymentLink,
  generatePaymentLink,
  clearPaymentlink
}) {
  const [formData, updateForm] = useState(initialFormState);

  const handleFormInputChange = name => e => {
    let { value } = e.target;

    if (name === "amount") {
      value = formatNumberWithComma(formatStrToNumber(value));
    }
    updateForm({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = () => {
    const payload = {
      description: formData.description,
      local_price: {
        amount: formatStrToNumber(formData.amount).toString(),
        currency
      }
    };
    generatePaymentLink(payload);
  };

  const formIncomplete = !(formData.description && formData.amount);

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        clearPaymentlink();
        updateForm(initialFormState);
        onClose();
      }}
      title="Accept payment"
      showClose
      darkCancelButton
      hideCancelButton={!paymentLink}
      actionButton={
        !paymentLink ? (
          <Button
            text="Continue"
            dark={true}
            disabled={formIncomplete}
            loading={fetchingPaymentLink}
            onClick={handleFormSubmit}
          />
        ) : null
      }
    >
      {paymentLink ? (
        <SpacedVerticalItems className="fadeIn">
          <P small>
            Share the link or embed payment button on your website to start
            accepting payment
          </P>
          <Copiable text={paymentLink} />
        </SpacedVerticalItems>
      ) : (
        <AcceptPaymentForm
          generatePaymentLink={generatePaymentLink}
          currency={currency}
          formData={formData}
          handleFormSubmit={handleFormSubmit}
          handleFormInputChange={handleFormInputChange}
        />
      )}
    </Modal>
  );
}

function mapStateToProps({ payments, business }) {
  return {
    paymentLink: payments.paymentlink.link,
    currency: business.businesses[business.activeBusiness].currency,
    fetchingPaymentLink: payments.paymentlink.fetching
  };
}

export default connect(
  mapStateToProps,
  {
    generatePaymentLink,
    clearPaymentlink
  }
)(AcceptPaymentModal);
