import styled, { css } from "styled-components";

import dropdownIcon from "../../assets/img/chevron-down.svg";
import dropdownIconGreen from "../../assets/img/chevron-down-green.svg";

export default styled.select`
  background: url(${dropdownIcon}), rgba(242, 241, 238, 0.6);
  background-repeat: no-repeat;
  background-position: 95% 50%;
  appearance: textfield;
  border-radius: 3px;
  border: 0.5px solid rgba(15, 15, 15, 0.1);
  width: 100%;
  height: 36px;
  outline: none;
  padding: 8px 15px;
  font-size: 0.7rem;
  font-family: "CharlieDisplay", sans-serif;

  transition: border 0.2s ease-in-out;

  ${props =>
    props.dark &&
    css`
      background: url(${dropdownIconGreen}), rgba(103, 196, 98, 0.12);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      border-radius: 3px;
      color: #529c4e;
    `};

  ${props =>
    props.transparent &&
    css`
      background-color: rgba(0, 0, 0, 0);
    `};

  &.wrap {
    width: auto;
    padding-right: 35px;
    background-position: 90% 50%;
  }

  &:focus {
    border: 0.5px solid #529c4e;
  }

  option {
    margin-right: 10px;
  }
`;
