import styled from "styled-components";

export default styled.section`
  margin-top: 10px;
  width: 100%;
  background-color: #f9fff8;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #d7d7e0;
  .file-name {
    padding: 10px 13px;
    color: #172b4d;
    text-align: left;
  }

  .progress {
    background-color: #529c4e;
    height: 4px;
    width: ${props => props.progress || "0"}%;
  }
`;
