import uniqid from "uniqid";
import mapKeys from "lodash/mapKeys";

import {
  GET_TRANSFER_FEE,
  CREATE_TRANSFER,
  INITIALIZE_BULK_TRANSFER,
  INITIALIZE_BULK_TRANSFER_PROGRESS,
  UPDATE_BULK_TRANSFER_ITEM,
  CLEAR_BULK_TRANSFER_ERROR
} from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  fee: {
    fee: null,
    fetching: false,
    error: null
  },
  transfer: null,
  fetching: false,
  error: null,
  initializeBulk: {
    data: null,
    progress: 0,
    fetching: false,
    error: null
  }
};

export default function transfer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case GET_TRANSFER_FEE.pending:
      return {
        ...state,
        fee: {
          ...state.fee,
          error: null,
          fetching: true
        }
      };
    case GET_TRANSFER_FEE.rejected:
      return {
        ...state,
        fee: {
          ...state.fee,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case GET_TRANSFER_FEE.fulfilled:
      return {
        ...state,
        fee: {
          ...state.fee,
          error: null,
          fetching: false,
          fee: action.payload.data
        }
      };

    case CREATE_TRANSFER.pending:
      return {
        ...state,

        error: null,
        fetching: true
      };
    case CREATE_TRANSFER.rejected:
      return {
        ...state,

        fetching: false,
        error: parseError(action.payload)
      };
    case CREATE_TRANSFER.fulfilled:
      return {
        ...state,
        error: null,
        fetching: false,
        transfer: action.payload.data
      };

    // INITIALIZE_BULK_TRANSFER
    case INITIALIZE_BULK_TRANSFER.pending:
      return {
        ...state,

        initializeBulk: {
          ...state.initializeBulk,
          error: null,
          fetching: true
        }
      };
    case INITIALIZE_BULK_TRANSFER.rejected:
      return {
        ...state,

        initializeBulk: {
          ...state.initializeBulk,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case INITIALIZE_BULK_TRANSFER.fulfilled:
      const bulkTransfers = action.payload.data.map(_txn => ({
        ..._txn,
        id: uniqid()
      }));
      const data = mapKeys(bulkTransfers, _txn => _txn.id);

      return {
        ...state,

        initializeBulk: {
          ...state.initializeBulk,
          error: null,
          fetching: false,
          data
        }
      };
    case CLEAR_BULK_TRANSFER_ERROR.fulfilled:
      return {
        ...state,

        initializeBulk: {
          error: null
        }
      };
    case INITIALIZE_BULK_TRANSFER_PROGRESS:
      return {
        ...state,

        initializeBulk: {
          ...state.initializeBulk,
          progress: action.payload
        }
      };

    case UPDATE_BULK_TRANSFER_ITEM.fulfilled:
      return {
        ...state,

        initializeBulk: {
          ...state.initializeBulk,
          data: {
            ...state.initializeBulk.data,
            [action.payload.id]: action.payload
          }
        }
      };

    default:
      return state;
  }
}
