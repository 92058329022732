import styled from "styled-components";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  width: 100%;
  text-decoration: none;
  color: #42526e;
  display: flex;
  align-items: stretch;
  padding: 10px 0px;
  svg {
    margin-right: 15px;
  }
  svg use {
    fill: #42526e;
  }
  &.active,
  &:hover {
    color: #529c4e;
    svg use,
    svg path {
      fill: #529c4e;
    }
  }
`;
