import React from "react";
import { Switch, Route } from "react-router-dom";

import Business from "../pages/Business";

const routes = [
  {
    path: "",
    component: Business
  }
];

function BusinessRoutes(props) {
  return (
    <Switch>
      {routes.map(({ path, component }, i) => (
        <Route
          key={`${i}-${path}`}
          path={`${props.match.path}/${path}`}
          component={component}
        />
      ))}
    </Switch>
  );
}

export default BusinessRoutes;
