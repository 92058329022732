import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  & > img {
    height: 20px;
  }
  .count {
    position: absolute;
    top: 0;
    right: -20%;
    background-color: #de3618;
    padding: 5px;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
  }
`;
