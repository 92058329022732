import api from "../../utils/api";

import {
  FETCH_PAYMENTS,
  RESOLVE_PAYMENT,
  GENERATE_PAYMENT_LINK,
  CLEAR_PAYMENT_LINK
} from "./actionTypes";

export const getPayments = options => (dispatch, getState) => {
  const payload = api.get(
    `/businesses/${getState().business.activeBusiness}/payments`,
    {
      params: options
    }
  );
  return dispatch({ type: FETCH_PAYMENTS.default, payload });
};

export const generatePaymentLink = formData => (dispatch, getState) => {
  const payload = api.post(
    `/businesses/${getState().business.activeBusiness}/charges`,
    formData
  );
  return dispatch({ type: GENERATE_PAYMENT_LINK.default, payload });
};

export const clearPaymentlink = () => dispatch => {
  return dispatch({ type: CLEAR_PAYMENT_LINK });
};

export const resolvePayment = code => dispatch => {
  const payload = api.post(`/businesses/charges/${code}/resolve`);
  return dispatch({ type: RESOLVE_PAYMENT.default, payload });
};
