import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
from {
    opacity: 0;
}

to {
    opacity: 1;
}
`;

export default styled.div`
  margin-top: 20px;
  /* position: absolute; */
  animation: ${fadeIn} 0.3s ease-in-out;

  .items {
    height: 110px;
    overflow: scroll;

    & > div:not(:first-child) {
      margin-top: 18px;
    }
    margin-bottom: 10px;
  }

  .footer {
    border-top: 1px solid #e9e9e9;
    padding: 10px;
    a {
      text-decoration: none;
    }
  }

  ${props =>
    props.show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
