import React from "react";
import { connect } from "react-redux";

import { redirectToAuth, signOut } from "../utils/helpers";
import Loader from "./Loader";

const RedirectToAuthServer = props => {
  if (!props.auth.loggedOut) redirectToAuth();
  else signOut();
  return <Loader />; //<h1>Redirecting....</h1>;
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(RedirectToAuthServer);
