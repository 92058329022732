import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import Select from "./Select";
import TextArea from "./TextArea";

const FormInput = styled.section`
  width: ${props => (props.fullWidth ? "100%" : "428px")};
  &:focus-within {
    & > label {
      color: #529c4e;
    }
  }

  &.search-padding {
    padding: 20px 20px 0px;
  }

  &.search-with-btn {
    margin-right: 20px;
  }

  ${props =>
    props.error &&
    css`
      & > label {
        color: #eb5757;
      }
      & > input,
      & > select {
        bordr-color: #eb5757;
      }
    `};
`;

FormInput.Label = Label;
FormInput.Input = Input;
FormInput.Select = Select;
FormInput.TextArea = TextArea;

export default FormInput;
