import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import LoaderBox from "../blocks/LoaderBox";

function Loader(props) {
  return (
    <LoaderBox>
      <ClipLoader size={props.size || 60} color={props.color || "#67C462"} />
    </LoaderBox>
  );
}

export default Loader;
