import styled, { css } from "styled-components";
import Label from "./Label";

const Switch = styled.div`
  display: flex;
  align-items: center;

  .react-switch-handle {
    ${props =>
      props.checked
        ? css`
            border: 1px solid #529c4e !important;
          `
        : css`
            border: 1px solid #798395 !important;
          `};
  }
  .react-switch-bg {
    ${props =>
      props.checked
        ? css`
            border: 0.5px solid rgba(0, 0, 0, 0.2) !important;
          `
        : css`
            border: 0.5px solid #798395 !important;
          `};
  }
`;

Switch.Label = Label;

export default Switch;
