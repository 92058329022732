import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import Loader from "../../components/Loader";
import { setToken } from "../../redux/auth/actions";
import { getUser } from "../../redux/user/actions";
import { getBusinesses } from "../../redux/business/actions";
import { redirectToAuth } from "../../utils/helpers";

class Auth extends React.Component {
  userIsLoggedIn = () => {
    const { auth } = this.props;
    return auth.data && auth.data.access_token;
  };

  componentDidMount() {
    const { setToken, location } = this.props;

    const searchParams = location.search;
    const authorize_token = new URLSearchParams(searchParams).get("authorize");
    if (authorize_token) {
      setToken(authorize_token);
      this.getUserAndBusinesses();
    } else if (this.userIsLoggedIn()) {
      this.getUserAndBusinesses();
    } else {
      redirectToAuth();
    }
  }

  getUserAndBusinesses() {
    const { getUser, getBusinesses } = this.props;
    getUser().then(() => {
      getBusinesses();
    });
  }

  componentDidUpdate(prevProps) {
    const { user, business } = this.props;
    if (user.user && business.businesses) {
      // console.log({ user, business });
      if (!isEmpty(business.businesses)) {
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/business");
      }
    }
  }

  render() {
    return <Loader />;
  }
}

function matStateToProps(state) {
  return { auth: state.auth, user: state.user, business: state.business };
}

export default connect(
  matStateToProps,
  { setToken, getUser, getBusinesses }
)(Auth);
