import capitalize from "lodash/capitalize";
import trim from "lodash/trim";

//navigates user to authentication server
export const redirectToAuth = () => {
  const redirect = process.env.REACT_APP_AUTH_REDIRECT;
  window.location.replace(
    `${process.env.REACT_APP_AUTH}/login?redirect=${redirect}`
  );
};

//execute signout and redirect
export const signOut = () => {
  const redirect = process.env.REACT_APP_AUTH_REDIRECT;
  window.location.replace(
    `${process.env.REACT_APP_AUTH}/login?redirect=${redirect}&logout=true`
  );
};

export function getRoutName(routerProp) {
  // console.log(routerProp);
  const splittedUrl = trim(
    routerProp.location.pathname.replace(routerProp.match.path, ""),
    "/"
  ).split("/");
  // const title = capitalize(
  //   (splittedUrl[splittedUrl.length - 1] || "Dashboard").split("-").join(" ")
  // );
  const title = capitalize(splittedUrl[0] || "Dashboard");
  // console.log({ title });
  return title;
}
