import styled, { css } from "styled-components";

export default styled.div`
  width: 100%;
  position: relative;
  &:first-of-type {
    height: 65px;
  }
  ${props =>
    props.links &&
    css`
      flex: 1;
      overflow-y: scroll;
    `};
`;
