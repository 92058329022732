import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #dddddd;
  padding: 4px 6px;
  border-radius: 2px;
`;
