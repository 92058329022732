import styled, { css } from "styled-components";

export default styled.h2`
  ${props =>
    props.small &&
    css`
      font-size: 0.8rem;
      font-weight: 600;
    `};

  ${props =>
    props.medium &&
    css`
      font-size: 0.9rem;
      font-weight: 600;
    `};

  ${props =>
    props.big &&
    css`
      font-size: 1.6rem;
      font-weight: 600;
    `};
`;
