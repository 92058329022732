import styled from "styled-components";
import DropDown from "./Dropdown";
import Info from "./Info";
import Item from "./Item";
import Seperator from "./Seperator";

const NotificationDropdown = styled.div`
  position: relative;
  margin-right: 40px;
`;

NotificationDropdown.Info = Info;
NotificationDropdown.Dropdown = DropDown;
NotificationDropdown.Item = Item;
NotificationDropdown.Seperator = Seperator;

export default NotificationDropdown;
