import styled from "styled-components";

const CentererdContentAndText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  & > * {
    margin: 5px 0;
  }
`;

export default CentererdContentAndText;
