import styled from "styled-components";

export default styled.section`
  padding-bottom: 28px;
  .label {
  }
  .option {
    font-family: "CharlieDisplay", sans-serif;
    margin-top: 14px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #e2e8f0;
    border-radius: 3px;
    /* box-shadow: inset 0 1px 1px 0 rgba(15, 15, 15, 0.1),
      inset 0 0 0 1px rgba(15, 15, 15, 0.1); */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 14px 12px;
    transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;
    &:hover {
      border-color: #529c4e;
      background-color: rgba(249, 255, 248, 0.6);
      box-shadow: inset 0 1px 1px 0 rgba(15, 15, 15, 0.1),
        inset 0 0 0 1px rgba(15, 15, 15, 0.1);
    }
  }
`;
