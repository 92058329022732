import React from "react";
import ReactModal from "react-modal";

import ModalBlock from "../blocks/Modal";
import H2 from "../elements/H2";
import Button from "./Button";

import { ReactComponent as CloseIcon } from "../assets/img/close-icon.svg";

function Modal({
  isOpen,
  onClose,
  onAfterOpen,
  contentLabel,
  title,
  actionButton,
  hideCancelButton = false,
  showClose = false,
  onCancel,
  cancelText = "Close",
  shouldCloseOnOverlayClickOrEsc = true,
  noFooter = false,
  darkCancelButton = false,
  ...props
}) {
  const customStyles = {
    ...ReactModal.defaultStyles,
    overlay: {
      ...ReactModal.defaultStyles.overlay,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      zIndex: 15
    },
    content: {
      ...ReactModal.defaultStyles.content,
      padding: "0px",
      maxWidth: "480px",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: 0,
      bottom: "unset",
      left: "unset",
      top: "50%",
      right: "50%",
      transform: "translate(50%, -50%)"
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={contentLabel || "Modal"}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClickOrEsc}
      shouldCloseOnEsc={shouldCloseOnOverlayClickOrEsc}
    >
      <ModalBlock>
        {title && (
          <ModalBlock.Header>
            <H2 medium>{title}</H2>
            {showClose && (
              <button className="close-button" onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </ModalBlock.Header>
        )}
        <ModalBlock.Body>{props.children}</ModalBlock.Body>
        {!noFooter && (
          <ModalBlock.Footer>
            {!hideCancelButton && (
              <Button
                clear={!darkCancelButton}
                dark={darkCancelButton}
                text={cancelText}
                onClick={onCancel || onClose}
              />
            )}
            {actionButton}
          </ModalBlock.Footer>
        )}
      </ModalBlock>
    </ReactModal>
  );
}

export default Modal;
