import api from "../../utils/api";
// import storage from "../../utils/storage";
import { FETCH_USER } from "./actionTypes";

export const getUser = () => dispatch => {
  const payload = api.get(`${process.env.REACT_APP_ACCOUNT}/v1/me`);
  return dispatch({ type: FETCH_USER.default, payload }).then(res => {
    // storage.set("user", res.value.data);
    return res;
  });
};
