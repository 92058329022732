// import mapKeys from "lodash/mapKeys";

import {
  FETCH_WEB_HOOKS,
  CREATE_WEB_HOOK,
  DELETE_WEB_HOOK
} from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  webHooks: null,
  fetching: false,
  error: null,
  createWebHook: { fetching: false, error: null },
  deleteWebHook: { fetching: false, error: null }
};

export default function webHooks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_WEB_HOOKS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_WEB_HOOKS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_WEB_HOOKS.fulfilled:
      return {
        ...state,
        fetching: false,
        webHooks: action.payload.data // mapKeys(action.payload.data, _w => _w.currency)
      };

    case CREATE_WEB_HOOK.pending:
      return {
        ...state,
        createWebHook: {
          error: null,
          fetching: true
        }
      };
    case CREATE_WEB_HOOK.rejected:
      return {
        ...state,
        createWebHook: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case CREATE_WEB_HOOK.fulfilled:
      return {
        ...state,
        createWebHook: {
          error: null,
          fetching: false
        },
        webHooks: (state.webHooks || []).concat([action.payload.data])
      };

    // DELETE_WEB_HOOK
    case DELETE_WEB_HOOK.pending:
      return {
        ...state,
        deleteWebHook: {
          error: null,
          fetching: true
        }
      };
    case DELETE_WEB_HOOK.rejected:
      return {
        ...state,
        deleteWebHook: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case DELETE_WEB_HOOK.fulfilled:
      const { webHookID } = action.meta;
      const webHooks = state.webHooks.filter(
        _webHook => _webHook.id !== webHookID
      );
      return {
        ...state,
        deleteWebHook: {
          error: null,
          fetching: false
        },
        webHooks
      };

    default:
      return state;
  }
}
