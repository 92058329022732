import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import RedirectToAuthServer from "../components/RedirectToAuthServer";

function ProtectedRoute({ component: Component, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps =>
        auth.data && auth.data.access_token ? (
          <Component {...routeProps} />
        ) : (
          <RedirectToAuthServer />
          // <Redirect
          //  to={{
          //    pathname: "/login",
          //    state: { from: routeProps.location }
          //  }}
          // />
        )
      }
    />
  );
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(ProtectedRoute);
