import styled from "styled-components";

export default styled.section`
  flex: 1;
  width: 934px;
  margin: 0 auto;
  /* height: 100%; */
  overflow-y: scroll;
  padding: 17px 0px;

  /* TABLETS BELOW */
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 30px 17px;
  }
`;
