function formatNumberWithComma(number = 0) {
  return number.toLocaleString("en-EN");
}

function formatStrToNumber(numberStr = "") {
  const number = parseFloat(numberStr.replace(/[^0-9.]/g, ""));
  return isNaN(number) ? 0 : number;
}

function formatNumberToDigitRank(number = 0) {
  const MILLION = 1000000;
  const THOUSAND = 1000;
  const HUNDRED = 100;
  if (number >= MILLION) {
    return `${number / MILLION} m`;
  } else if (number >= THOUSAND) {
    return `${number / THOUSAND} k`;
  } else if (number >= HUNDRED) {
    return `${number / HUNDRED} h`;
  } else {
    return number;
  }
}

export { formatNumberToDigitRank, formatNumberWithComma, formatStrToNumber };
