import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ReactModal from "react-modal";

import { store, persistor } from "./redux";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./containers/ProtectedRoute";
import BusinessRoutes from "./routes/Business";
import DashboardRoutes from "./routes/Dashboard";
import Loader from "./components/Loader";

// const { store, persistor } = configureStore;

ReactModal.setAppElement("#root");

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        className="toast__container"
        toastClassName="toast__toast"
        bodyClassName="toast__body"
        hideProgressBar={true}
        closeButton={false}
        // autoClose={false}
        position="top-center"
      />
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Router>
          <Switch>
            <Redirect from="/" to="/dashboard" exact />
            <Route path="/oauth2" component={Auth} />
            <ProtectedRoute path="/business" component={BusinessRoutes} />
            <ProtectedRoute path="/dashboard" component={DashboardRoutes} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
