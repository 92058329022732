import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import BusinessForm from "../../blocks/BusinessForm";
import Card from "../../blocks/Card";
import Button from "../../components/Button";
import P from "../../elements/P";
import FormInput from "../../blocks/FormInput";

import countries from "../../utils/countries";
import { createBusiness } from "../../redux/business/actions";
import Notification from "components/Notification";

class Business extends React.Component {
  state = {
    name: "",
    // loading: false,
    currency_code: "NGN"
  };

  canSubmit = () => {
    const { name, currency_code } = this.state;
    return name && currency_code;
  };

  handleChange = name => e => this.setState({ [name]: e.target.value });

  onFormSubmit = e => {
    e.preventDefault();
    if (!this.canSubmit()) return;
    const { name, currency_code } = this.state;
    this.props.createBusiness({ name, currency_code }).then(() => {
      toast(
        <Notification status="success" title="Business Created Successfully" />
      );
      this.props.history.push("/dashboard");
    });
    // .catch(error => {
    //   toast.error(this.props.business.createBusiness.error);
    // });
  };
  render() {
    const { name, currency_code } = this.state;
    const {
      business: {
        createBusiness: { fetching }
      }
    } = this.props;

    return (
      <BusinessForm>
        <BusinessForm.Logo>
          <Link to="/dashboard">
            <img src={process.env.REACT_APP_LOGO} alt="logo" />
          </Link>
        </BusinessForm.Logo>
        <BusinessForm.Form>
          <Card noBorder>
            <form onSubmit={this.onFormSubmit}>
              <P big semiBold>
                Please tell us about your business
              </P>
              <FormInput fullWidth>
                <FormInput.Label>Business name</FormInput.Label>
                <FormInput.Input
                  value={name}
                  onChange={this.handleChange("name")}
                />
              </FormInput>
              <FormInput fullWidth>
                <FormInput.Label>Default currency</FormInput.Label>
                <FormInput.Select
                  value={currency_code}
                  onChange={this.handleChange("currency_code")}
                >
                  <option value="" />
                  {countries.map(({ country, currency_code }) => (
                    <option
                      key={`${country} (${currency_code})`}
                      value={currency_code}
                    >
                      {`${country} (${currency_code})`}
                    </option>
                  ))}
                </FormInput.Select>
              </FormInput>
              <Button
                light
                fullWidth
                text="Create business"
                disabled={!this.canSubmit()}
                loading={fetching}
              />
            </form>
          </Card>
        </BusinessForm.Form>
        <P small>
          By clicking “Create business” above, you acknowledge that you have
          read and understood, and agree to Busha Pay’s Terms and condiitons
        </P>
      </BusinessForm>
    );
  }
}

function mapStateToProps({ business }) {
  return { business };
}

export default connect(mapStateToProps, { createBusiness })(Business);
