import { combineReducers } from "redux";
import analyticsReducer from "./analytics";
import authReducer from "./auth";
import userReducer from "./user";
import businessReducer from "./business";
import wallets from "./wallets";
import apiKeys from "./apiKeys";
import webHooks from "./webHooks";
import payments from "./payments";
import transfers from "./transfers";
import banks from "./banks";
import businessBanks from "./businessBanks ";
import transfer from "./transfer";

const reducers = combineReducers({
  analytics: analyticsReducer,
  auth: authReducer,
  user: userReducer,
  business: businessReducer,
  wallets,
  apiKeys,
  webHooks,
  payments,
  transfers,
  banks,
  businessBanks,
  transfer
});

export default reducers;
