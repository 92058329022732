import styled, { css } from "styled-components";

const SpacedVerticalItems = styled.div`
  ${props =>
    css`
      & > * {
        margin-bottom: ${props.space || "20"}px;
      }
      & > *:last-child {
        margin-bottom: 0;
      }
    `};
`;

export default SpacedVerticalItems;
