import actionTypes from "../actionTypes";

export const FETCH_BUSINESSES = actionTypes("FETCH_BUSINESSES");
export const GET_BUSINESS = actionTypes("FETCH_BUSINESS");
export const SET_ACTIVE_BUSINESS = actionTypes("SET_ACTIVE_BUSINESS");
export const CREATE_BUSINESS = actionTypes("CREATE_BUSINESS");

export const UPDATE_BUSINESS_EMAIL_PREFERENCE = actionTypes(
  "UPDATE_BUSINESS_EMAIL_PREFERENCE"
);

export const UPDATE_BUSINESS_AUTO_CONVERT = actionTypes(
  "UPDATE_BUSINESS_AUTO_CONVERT"
);

export const UPDATE_BUSINESS = actionTypes("UPDATE_BUSINESS");

export const UPDATE_BUSINESS_ALWAYS_CONVERT = actionTypes(
  "UPDATE_BUSINESS_ALWAYS_CONVERT"
);
