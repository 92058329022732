import styled from "styled-components";

const DashboardHeader = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 21px;

  .selectors {
    display: flex;
    & > :first-child {
      margin-right: 25px;
    }
  }
`;

export default DashboardHeader;
