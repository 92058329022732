import styled from "styled-components";

export default styled.section`
  display: flex;
  background-color: rgba(242, 241, 238, 0.6);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(15, 15, 15, 0.1),
    inset 0 0 0 1px rgba(15, 15, 15, 0.1);
  & > input {
    border-left: none;
  }
  & > select {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
