import styled from "styled-components";

export default styled.div`
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
