import { GET_PAYMENT_ANALYTICS, GET_TRANSFER_ANALYTICS } from "./actionTypes";

import parseError from "utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  payments: { fetching: false, error: null, data: { points: [] } },
  transfers: { fetching: false, error: null, data: { points: [] } }
};

export default function analytics(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case GET_PAYMENT_ANALYTICS.pending:
      return {
        ...state,
        payments: {
          ...state.payments,
          fetching: true,
          error: false
        }
      };
    case GET_PAYMENT_ANALYTICS.rejected:
      return {
        ...state,
        payments: {
          ...state.payments,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case GET_PAYMENT_ANALYTICS.fulfilled:
      return {
        ...state,
        payments: {
          ...state.payments,
          fetching: false,
          error: null,
          data: action.payload.data
        }
      };
    case GET_TRANSFER_ANALYTICS.pending:
      return {
        ...state,
        transfers: {
          ...state.transfers,
          fetching: true,
          error: false
        }
      };
    case GET_TRANSFER_ANALYTICS.rejected:
      return {
        ...state,
        transfers: {
          ...state.transfers,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case GET_TRANSFER_ANALYTICS.fulfilled:
      return {
        ...state,
        transfers: {
          ...state.transfers,
          fetching: false,
          error: null,
          data: action.payload.data
        }
      };

    default:
      return state;
  }
}
