import styled, { keyframes } from "styled-components";
import Section from "./Section";
import Link from "./Link";

const fadeIn = keyframes`
from {
    opacity: 0;
}

to {
    opacity: 1;
}
`;

const Sidebar = styled.aside`
  width: 240px;
  height: 100%;
  border-right: 1px solid #e9e9e9;
  padding: 34px 15px;
  display: flex;
  flex-direction: column;

  /* TABLETS BELOW */
  @media only screen and (max-width: 1024px) {
    animation: ${fadeIn} 0.2s ease-in-out;
    display: none;
    &.show {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

Sidebar.Section = Section;
Sidebar.Link = Link;

export default Sidebar;
