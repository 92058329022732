import { FETCH_USER } from "./actionTypes";
// import storage from "../../utils/storage";
import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  user: null,
  fetching: false,
  error: null
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_USER.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_USER.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_USER.fulfilled:
      return {
        ...state,
        fetching: false,
        user: action.payload.data
      };

    default:
      return state;
  }
}
