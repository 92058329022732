import React from "react";
import ReactSwitch from "react-switch";

import SwitchBlock from "../blocks/Switch";

function Switch({
  label,
  labelPosition = "right",
  checked,
  onChange,
  disabled = false
}) {
  const Label = () => {
    const onLabelClicked = e => {
      if (!disabled) onChange(!checked);
    };
    return (
      <SwitchBlock.Label
        onClick={onLabelClicked}
        medium
        semiBold
        labelPosition={labelPosition}
      >
        {label}
      </SwitchBlock.Label>
    );
  };
  return (
    <SwitchBlock checked={checked}>
      {label && labelPosition === "left" && <Label />}
      <ReactSwitch
        height={14}
        width={33}
        handleDiameter={16}
        onColor="#67C462"
        offColor="#fff"
        checkedIcon={false}
        uncheckedIcon={false}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label && labelPosition === "right" && <Label />}
    </SwitchBlock>
  );
}

export default Switch;
