// import mapKeys from "lodash/mapKeys";

import { FETCH_API_KEYS, CREATE_API_KEY, DELETE_API_KEY } from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  apiKeys: null,
  fetching: false,
  error: null,
  createKey: { fetching: false, error: null },
  deleteKey: { fetching: false, error: null }
};

export default function apiKeys(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_API_KEYS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_API_KEYS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_API_KEYS.fulfilled:
      return {
        ...state,
        fetching: false,
        apiKeys: action.payload.data // mapKeys(action.payload.data, _w => _w.currency)
      };

    case CREATE_API_KEY.pending:
      return {
        ...state,
        createKey: {
          error: null,
          fetching: true
        }
      };
    case CREATE_API_KEY.rejected:
      return {
        ...state,
        createKey: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case CREATE_API_KEY.fulfilled:
      return {
        ...state,
        createKey: {
          error: null,
          fetching: false
        },
        apiKeys: (state.apiKeys || []).concat([action.payload.data])
      };

    // DELETE_API_KEY
    case DELETE_API_KEY.pending:
      return {
        ...state,
        deleteKey: {
          error: null,
          fetching: true
        }
      };
    case DELETE_API_KEY.rejected:
      return {
        ...state,
        deleteKey: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case DELETE_API_KEY.fulfilled:
      const { apiKey } = action.meta;
      const apiKeys = state.apiKeys.filter(_apiKey => _apiKey.key !== apiKey);
      return {
        ...state,
        deleteKey: {
          error: null,
          fetching: false
        },
        apiKeys
      };

    default:
      return state;
  }
}
