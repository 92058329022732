import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import Dashboard from "../pages/Dashboard";
import Layout from "../blocks/Layout";
import SideNav from "../components/SideNav";
import Main from "../blocks/Main";
import TopNav from "../components/TopNav";
import Loader from "../components/Loader";

const routes = [
  {
    path: "",
    component: Dashboard,
    exact: true
  },
  {
    path: "balances",
    component: lazy(() => import("../pages/Balances")),
    exact: true
  },
  {
    path: "payments",
    component: lazy(() => import("../pages/Payments")),
    exact: true
  },
  {
    path: "transfers",
    component: lazy(() => import("../pages/Transfers")),
    exact: false
  },
  {
    path: "settings",
    component: lazy(() => import("../pages/Settings")),
    exact: false
  },
  {
    path: "developers",
    component: lazy(() => import("../pages/Developers")),
    exact: false
  }
];

function DashboardRoutes(props) {
  const [showSideNav, setShowShowSideNav] = React.useState(false);

  const toggleSideNav = e => setShowShowSideNav(!showSideNav);

  useEffect(() => {
    const { business } = props;
    if (isEmpty(business.businesses)) {
      props.history.push("/business");
    }
  }, [props, props.business.businesses]);

  return (
    <Layout>
      <SideNav show={showSideNav} />
      <Main className={showSideNav && "side-bar-open"}>
        <TopNav toggleSideNav={toggleSideNav} showSideNav={showSideNav} />
        <Main.Body>
          <Suspense fallback={<Loader />}>
            <Switch>
              {routes.map(({ path, component, exact }, i) => (
                <Route
                  key={`${i}-${path}`}
                  path={`${props.match.path}/${path}`}
                  component={component}
                  exact={exact}
                />
              ))}
            </Switch>
          </Suspense>
        </Main.Body>
      </Main>
    </Layout>
  );
}

function mapStateToProps({ business }) {
  return { business };
}

export default connect(mapStateToProps)(DashboardRoutes);
