import React from "react";

function ClickAway({ onClickAway, fullWidth = false, ...props }) {
  const ref = React.useRef(null);

  const handleClick = e => {
    if (!ref.current.contains(e.target)) {
      onClickAway();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });
  return (
    <div
      style={{
        width: fullWidth ? "100%" : "max-content",
        height: "auto"
      }}
      ref={ref}
    >
      {props.children}
    </div>
  );
}

export default ClickAway;
