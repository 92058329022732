import React from "react";
import { withRouter } from "react-router-dom";

import Topbar from "../blocks/Topbar";
import H2 from "../elements/H2";
import UserDropDown from "./UserDropdown";
import Switch from "./Switch";
import Notifications from "./Notifications";
import { getRoutName } from "../utils/helpers";

// import { ReactComponent as MenuIcon } from "../assets/img/menu-icon.svg";
// import { ReactComponent as CloseIcon } from "../assets/img/close-icon.svg";

// import log from "../utils/log";

function TopNav(props) {
  const [testMode, setTestMode] = React.useState(false);

  const { showSideNav, toggleSideNav } = props;

  const title = getRoutName(props);
  return (
    <Topbar>
      <Topbar.Body>
        <button className="menu" onClick={toggleSideNav}>
          {/* {showSideNav ? <CloseIcon /> : <MenuIcon />} */}
          <div id="nav-icon" className={showSideNav ? "open" : ""}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="section">
          <H2 big>{title}</H2>
        </div>
        <div className="section">
          <Topbar.SwitchBox>
            <Switch
              checked={testMode}
              onChange={checked => setTestMode(checked)}
              label="Test Mode"
              disabled={true}
            />
          </Topbar.SwitchBox>
          <Notifications />
          <UserDropDown />
        </div>
      </Topbar.Body>
    </Topbar>
  );
}

export default withRouter(TopNav);
