import styled from "styled-components";
import Text from "./Text";
import Button from "./Button";

const CopyBox = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 16px;
  background-color: rgba(242, 241, 238, 0.6);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(15, 15, 15, 0.1),
    inset 0 0 0 1px rgba(15, 15, 15, 0.1);
  /* width: 320px; */
  min-height: 36px;
  * {
    font-family: "CharlieDisplay", sans-serif;
  }
`;

CopyBox.Text = Text;
CopyBox.Button = Button;

export default CopyBox;
