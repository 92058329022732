import CurrecySymbols from "constants/CurrencySymbols";

function getCurrencyCodeSymbol(currencyCode = "") {
  return CurrecySymbols[currencyCode] || "";
}

function formatNumberToMoney({
  number = 0,
  countryCode = "NG",
  currencyCode = "NGN"
} = {}) {
  const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2
  });
  return formatter.format(number);
}

export { getCurrencyCodeSymbol, formatNumberToMoney };
