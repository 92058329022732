import styled, { css } from "styled-components";

export default styled.label`
  cursor: pointer;
  ${props =>
    props.small &&
    css`
      font-size: 0.7rem;
      font-weight: normal;
    `};
  ${props =>
    props.medium &&
    css`
      font-size: 0.8rem;
      font-weight: normal;
    `};
  ${props =>
    props.semiBold &&
    css`
      font-weight: 600;
    `};

  ${props => ({ labelPosition }) =>
    labelPosition === "right"
      ? css`
          padding-left: 8px;
        `
      : css`
          padding-right: 8px;
        `};
`;
