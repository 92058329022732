import React from "react";

import Icon from "../assets/img/notification-icon.svg";
import NotificationDropdown from "../blocks/NotificationDropdown";

function Notifications(props) {
  return (
    <NotificationDropdown>
      <NotificationDropdown.Info>
        <img src={Icon} alt="notifications" />
        <div className="count">4</div>
      </NotificationDropdown.Info>
      <NotificationDropdown.Dropdown />
    </NotificationDropdown>
  );
}

export default Notifications;
