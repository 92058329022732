import React from "react";
import { toast } from "react-toastify";

import isPromise from "../../utils/isPromise";
import parseError from "../../utils/parseError";
import Notification from "../../components/Notification";

export default function asyncError(store) {
  return next => action => {
    if (!isPromise(action.payload)) {
      return next(action);
    }
    return next(action).catch(error => {
      // console.log({error});  TODO any action u wanna take
      const errorMessage = parseError(error);
      toast(<Notification status="error" title={errorMessage} />);
      throw error;
    });
  };
}
