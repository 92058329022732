import { LOGIN_USER, LOGOUT_USER, SET_TOKEN, CLEAR_ERROR } from "./actionTypes";
// import storage from "../../utils/storage";
import parseError from "../../utils/parseError";

const INITIAL_STATE = {
  data: null,
  loggingIn: false,
  loggedOut: false,
  error: null
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return {
        ...INITIAL_STATE,
        loggedOut: true
      };
    case LOGIN_USER.pending:
      return {
        ...state,
        error: null,
        loggingIn: true
      };
    case LOGIN_USER.rejected:
      return {
        ...state,
        loggingIn: false,
        error: parseError(action.payload)
      };
    case LOGIN_USER.fulfilled:
      return {
        ...state,
        loggingIn: false,
        loggedOut: false,
        data: action.payload.data
      };
    case SET_TOKEN.fulfilled:
      return {
        ...state,
        loggedOut: false,
        data: {
          ...state.data,
          access_token: action.payload.token
        }
      };
    case CLEAR_ERROR.fulfilled:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
