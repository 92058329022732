import styled, { css } from "styled-components";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

const Card = styled.section`
  border: 1px solid #dfe1e6;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.1),
    0 0 0 1px rgba(15, 15, 15, 0.06);
  width: 100%;
  /* height: 10px; */
  min-height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;

  ${props =>
    props.noBorder &&
    css`
      border: none;
    `};
  ${props =>
    props.elevated &&
    css`
      box-shadow: 0 4px 14px 0 rgba(7, 68, 221, 0.1);
    `};
  ${props =>
    props.midHeight &&
    css`
      height: 400px;
    `};
  ${props =>
    props.fitChild &&
    css`
      height: auto;
      min-height: auto;
    `};
`;

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
