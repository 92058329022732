import styled from "styled-components";

import AccountName from "./AccountName";
import AmountInput from "./AmountInput";
import Fee from "./Fee";
import Dropzone from "./Dropzone";
import BulkTransferFooter from "./BulkTransferFooter";
import UploadBar from "./UploadBar";
import TransferReceipient from "./TransferReceipient";
import BulkTransferSelect from "./BulkTransferSelect";

const TransferForm = styled.section`
  transition: max-height 0.3s ease-in-out;
  &.modal-form {
    width: 440px;
  }
  height: auto;
  max-height: 800px;
  &.loading {
    max-height: 100px;
  }

  .form-input:not(:first-of-type) {
    margin-top: 20px;
  }

  .error-box {
    margin-top: 18px;
  }
`;

TransferForm.AccountName = AccountName;
TransferForm.AmountInput = AmountInput;
TransferForm.Fee = Fee;
TransferForm.Dropzone = Dropzone;
TransferForm.BulkTransferFooter = BulkTransferFooter;
TransferForm.UploadBar = UploadBar;
TransferForm.TransferReceipient = TransferReceipient;
TransferForm.BulkTransferSelect = BulkTransferSelect;

export default TransferForm;
