import api from "../../utils/api";
import {
  FETCH_BUSINESSES,
  SET_ACTIVE_BUSINESS,
  CREATE_BUSINESS,
  UPDATE_BUSINESS_EMAIL_PREFERENCE,
  UPDATE_BUSINESS_AUTO_CONVERT,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_ALWAYS_CONVERT,
  GET_BUSINESS
} from "./actionTypes";

export const getBusinesses = () => dispatch => {
  const payload = api.get(`/businesses`);
  return dispatch({ type: FETCH_BUSINESSES.default, payload });
};

export const getBusiness = businessId => dispatch => {
  const payload = api.get(`/businesses/${businessId}`);
  return dispatch({ type: GET_BUSINESS.default, payload });
};

export const setActiveBusiness = id => dispatch =>
  dispatch({ type: SET_ACTIVE_BUSINESS.fulfilled, payload: id });

export const createBusiness = data => dispatch => {
  const payload = api.post(`/businesses`, data);
  return dispatch({ type: CREATE_BUSINESS.default, payload });
};

export const updateBusinessEmailPreference = data => (dispatch, getState) => {
  const payload = api.patch(
    `/businesses/${getState().business.activeBusiness}/preferences/email`,
    data
  );
  return dispatch({ type: UPDATE_BUSINESS_EMAIL_PREFERENCE.default, payload });
};

export const updateBusinessAutoConvert = data => (dispatch, getState) => {
  const payload = api.patch(
    `/businesses/${getState().business.activeBusiness}/preferences/convert`,
    data
  );
  return dispatch({ type: UPDATE_BUSINESS_AUTO_CONVERT.default, payload });
};

export const updateBusinessAlwaysConvert = data => (dispatch, getState) => {
  const payload = api.patch(
    `/businesses/${
      getState().business.activeBusiness
    }/preferences/always_convert`,
    data
  );
  return dispatch({ type: UPDATE_BUSINESS_ALWAYS_CONVERT.default, payload });
};

export const updateBusiness = data => (dispatch, getState) => {
  const payload = api.patch(
    `/businesses/${getState().business.activeBusiness}`,
    data
  );
  return dispatch({ type: UPDATE_BUSINESS.default, payload });
};
