import React from "react";

import Btn from "../blocks/Button";
import P from "../elements/P";
import Loader from "./Loader";

function Button({
  fullWidth,
  text,
  icon: Icon,
  loading = false,
  iconRight = false,
  onClick,
  ...props
}) {
  const BtnIcon = () => (
    <Icon className={iconRight ? "icon-right" : "icon-left"} />
  );
  return (
    <Btn onClick={onClick} fullWidth={fullWidth} {...props}>
      {!loading ? (
        <>
          {Icon && !iconRight && <BtnIcon />}
          {text ? (
            <P small semiBold>
              {text}
            </P>
          ) : (
            props.children
          )}
          {Icon && iconRight && <BtnIcon />}
        </>
      ) : (
        <Loader size={20} color={props.dark && "#fff"} />
      )}
    </Btn>
  );
}

export default Button;
