import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import BusinessDropdown from "../blocks/BusinessDropdown";
import P from "../elements/P";

import { ReactComponent as Icon } from "../assets/img/dropdown-icon.svg";
import { ReactComponent as AddIcon } from "../assets/img/add-icon.svg";
import { setActiveBusiness } from "../redux/business/actions";

function BusinessItem({ main = false, padding = true, business, ...props }) {
  return (
    <BusinessDropdown.BusinessItem padding={padding} {...props}>
      {business && (
        <>
          <BusinessDropdown.BusinessIcon>
            <P tiny>{business.name[0]}</P>
          </BusinessDropdown.BusinessIcon>
          <P small semiBold={main}>
            {business.name}
          </P>
        </>
      )}
      {main && <Icon />}
    </BusinessDropdown.BusinessItem>
  );
}

function BusinessDropDown(props) {
  const [showDropdown, setShowDropdown] = React.useState(false);
  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }
  const { business } = props;
  const businessIds = Object.keys(business.businesses);
  const activeBusiness = business.activeBusiness || businessIds[0];
  if (!business.activeBusiness) {
    props.setActiveBusiness(businessIds[0]);
  }

  const setActiveBusiness = id => e => {
    props.setActiveBusiness(id);
    toggleDropdown();
  };

  return (
    <BusinessDropdown active={showDropdown}>
      <BusinessItem
        main={true}
        padding={showDropdown}
        business={business.businesses[activeBusiness]}
        onClick={toggleDropdown}
      />
      <BusinessDropdown.Dropdown show={showDropdown}>
        <div className="items">
          {businessIds.map(
            _bId =>
              activeBusiness !== _bId && (
                <BusinessItem
                  key={_bId}
                  business={business.businesses[_bId]}
                  onClick={setActiveBusiness(_bId)}
                />
              )
          )}
        </div>
        <div className="footer">
          <Link to="/business">
            <BusinessDropdown.AddButton>
              <AddIcon />
              <P small semiBold>
                Add a business
              </P>
            </BusinessDropdown.AddButton>
          </Link>
        </div>
      </BusinessDropdown.Dropdown>
    </BusinessDropdown>
  );
}

function mapStateToProps({ business }) {
  return { business };
}

export default connect(
  mapStateToProps,
  { setActiveBusiness }
)(BusinessDropDown);
