import mapKeys from "lodash/mapKeys";

import {
  FETCH_BANKS,
  VERIFY_BANK_ACCOUNT,
  CLEAR_BANK_VERIFY_ERROR
} from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  banks: null,
  fetching: false,
  error: null,
  verify: {
    data: null,
    fetching: false,
    error: null
  }
};

export default function banks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_BANKS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_BANKS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_BANKS.fulfilled:
      return {
        ...state,
        fetching: false,
        banks: mapKeys(action.payload.data, _b => _b.code) // action.payload.data
      };

    case VERIFY_BANK_ACCOUNT.pending:
      return {
        ...state,
        verify: {
          ...state.verify,
          error: null,
          fetching: true
        }
      };
    case VERIFY_BANK_ACCOUNT.rejected:
      return {
        ...state,
        verify: {
          ...state.verify,
          data: null,
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case VERIFY_BANK_ACCOUNT.fulfilled:
      return {
        ...state,
        verify: {
          ...state.verify,
          fetching: false,
          data: action.payload.data
        }
      };

    case CLEAR_BANK_VERIFY_ERROR.fulfilled:
      return {
        ...state,
        verify: {
          ...state.verify,
          error: null
        }
      };

    default:
      return state;
  }
}
