import styled from "styled-components";

export default styled.section`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > div {
    justify-content: flex-start;
  }
`;
