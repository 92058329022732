import styled, { css } from "styled-components";

const Button = styled.button`
  outline: none;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  /* padding: 10px 15px; */
  padding: 0px 15px;
  font-family: "CharlieDisplay", sans-serif;

  &.no-padding {
    padding: 0px;
  }

  & > svg.icon-left {
    margin-right: 5px;
  }
  & > svg.icon-right {
    margin-left: 5px;
  }
  ${props =>
    props.light &&
    css`
      background-color: rgba(103, 196, 98, 0.12);
      box-shadow: 0 1px 2px 0 rgba(15, 15, 15, 0.1),
        inset 0 0 0 1px rgba(103, 196, 98, 0.3);
      color: #529c4e;
      & > svg use {
        fill: #529c4e;
      }
      &:hover {
        background-color: rgba(103, 196, 98, 0.25);
      }
    `};
  ${props =>
    props.dark &&
    css`
      background-color: #67c462;
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: #ffffff;
      & > svg use {
        fill: #ffffff;
      }
      &:hover {
        background-color: #46aa41;
      }

      /* &:disabled {
        background-color: #67c462;
        opacity: 0.6368861607142857;
      } */
    `};
  ${props =>
    props.clear &&
    css`
      background-color: transparent;
      border: none;
      color: ${props => (props.danger ? "#DE3618" : "#529c4e")};
      & > svg use {
        fill: #529c4e;
      }
      &:hover {
        background-color: transparent;
      }
    `};
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      /* padding: 10px 0px; */
      padding: 0px 0px;
    `};
  ${props =>
    props.medium &&
    css`
      width: 155px;
      /* padding: 7px 0px; */
      padding: 0px;
    `};

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6368861607142857;
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `};
`;

export default Button;
