import styled from "styled-components";

export default styled.section`
  background-color: #f9fff8;
  border: 1px solid #e4f0e1;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #d7d7e0;
  margin-top: 12px;
  padding: 12px 16px;
`;
