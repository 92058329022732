import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import CopyBox from "../blocks/CopyBox";

import { ReactComponent as CopyIcon } from "../assets/img/copy-icon.svg";
import transformKey from "../utils/transformKey";
import { toast } from "react-toastify";
import Notification from "./Notification";

function Copiable({ text, transform = false, showIcon = true }) {
  const onCopy = () => toast(<Notification status="success" title="Copied!" />);
  return (
    <CopyBox>
      <CopyBox.Text>
        <code>{transform ? transformKey(text) : text}</code>
      </CopyBox.Text>
      {showIcon && (
        <CopyToClipboard text={text} onCopy={onCopy}>
          <CopyBox.Button>
            <CopyIcon />
            <span className="tooltiptext">Copy</span>
          </CopyBox.Button>
        </CopyToClipboard>
      )}
    </CopyBox>
  );
}

export default Copiable;
