import styled from "styled-components";

export default styled.label`
  display: block;
  font-size: 0.7rem;

  color: #42526e;

  margin-bottom: 5px;

  transition: color 0.2s ease-in-out;
`;
