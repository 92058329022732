import React from "react";
import Chart from "react-apexcharts";
import { formatNumberToDigitRank } from "utils/number";
import { getCurrencyCodeSymbol } from "utils/money";

function Area({ options, data, currencyCode, ...props }) {
  console.log(currencyCode);
  const chartOptions = {
    ...options,
    stroke: {
      ...options.stroke,
      curve: "straight"
    },
    markers: {
      ...options.markers,
      size: 3,
      colors: ["white"],
      strokeColors: ["#67C462"]
    },
    fill: {
      ...options.fill,
      gradient: {
        shade: "light",
        type: "vertical",
        colorStops: [
          {
            offset: 0,
            color: "rgba(228, 240, 236, 0.77)",
            opacity: 1
          },
          {
            offset: 100,
            color: "rgba(228, 240, 236, 0.77)",
            opacity: 0
          }
        ]
      }
    },
    yaxis: {
      ...options.yaxis,
      labels: {
        ...options.yaxis.labels,
        formatter: value =>
          `${getCurrencyCodeSymbol(currencyCode)} ${formatNumberToDigitRank(
            Number.parseFloat(value)
          )}`
      }
    }
  };
  return (
    <Chart
      type="area"
      options={chartOptions}
      height={options.chart.height}
      series={[
        {
          name: props.name || "",
          data: data
        }
      ]}
    />
  );
}

export default Area;
