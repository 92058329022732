import styled from "styled-components";

export default styled.div`
  width: 100%;
  text-decoration: none;
  color: #42526e;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  svg {
    margin-right: 15px;
  }
  svg path,
  svg use {
    fill: #42526e;
  }
  &.active,
  &:hover {
    color: #529c4e;
    svg use,
    svg path {
      fill: #529c4e;
    }
  }
`;
