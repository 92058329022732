import axios from "axios";

import { store } from "../redux";
import { redirectToAuth } from "./helpers";
import { logout } from "../redux/auth/actions";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE
});

instance.interceptors.request.use(config => {
  const { data } = store.getState().auth;

  if (!data) return config;

  const { access_token: token } = data;

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  } else {
    return config;
  }
});

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    console.error({ error });
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      redirectToAuth();
    }
    return Promise.reject(error);
  }
);

export default instance;
