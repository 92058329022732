import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import reducers from "./reducers";
import asyncError from "./middleware/asyncError";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(
  applyMiddleware(asyncError, reduxThunk, promise)
);

const persistConfig = {
  key: "busha-blink",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["auth", "user", "business"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, enhancers);
export const persistor = persistStore(store);

// export default () => {
//   let store = createStore(persistedReducer, enhancers);
//   let persistor = persistStore(store);
//   return { store, persistor };
// };

// const store = createStore(reducers, enhancers);

// export default store;
