import styled, { css } from "styled-components";
import BusinessItem from "./BusinessItem";
import Dropdown from "./Dropdown";
import BusinessIcon from "./BusinessIcon";
import AddButton from "./AddButton";

const BusinessDropdown = styled.div`
  cursor: pointer;
  padding-top: 10px;
  width: 100%;
  position: absolute;
  z-index: 10;

  /* transition: border-radius 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
  transition: all 0.2s ease-in-out;

  ${props => ({ active }) =>
    active &&
    css`
      background-color: #ffffff;
      border-radius: 3px;
      box-shadow: 0 1px 12px 0 rgba(195, 203, 222, 0.59);
    `};
`;

BusinessDropdown.BusinessItem = BusinessItem;
BusinessDropdown.Dropdown = Dropdown;
BusinessDropdown.BusinessIcon = BusinessIcon;
BusinessDropdown.AddButton = AddButton;

export default BusinessDropdown;
