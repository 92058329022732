import styled from "styled-components";

export default styled.section`
  width: 934px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  & > .section {
    /* flex: 50%; */
    display: flex;
    align-items: center;
  }

  & > .section:last-child {
    flex: 1;
    justify-content: flex-end;
  }

  & > .menu {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    margin-right: 20px;
    display: none;

    & > #nav-icon {
      width: 30px;
      height: 17px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

    & > #nav-icon span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      /* background: #d3531a; */
      background: #172b4d;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    #nav-icon span:nth-child(1) {
      top: 0px;
    }

    #nav-icon span:nth-child(2),
    #nav-icon span:nth-child(3) {
      top: 9px;
    }

    #nav-icon span:nth-child(4) {
      top: 18px;
    }

    #nav-icon.open span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    #nav-icon.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #nav-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #nav-icon.open span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }

  /* TABLETS BELOW */
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 30px;
    & > .menu {
      display: inline-block;
    }
  }
`;
