import React from "react";

import FormInput from "blocks/FormInput";
import TransferForm from "blocks/TransferForm";
import SpacedVerticalItems from "blocks/SpacedVerticalItems";

export default function AcceptPaymentForm({
  handleFormInputChange,
  handleFormSubmit,
  formData,
  currency
}) {
  return (
    <form onSubmit={handleFormSubmit}>
      <SpacedVerticalItems>
        <FormInput>
          <FormInput.Label>Accept payment for</FormInput.Label>
          <FormInput.Input
            value={formData.description}
            onChange={handleFormInputChange("description")}
          />
        </FormInput>
        <FormInput fullWidth={true} className="form-input">
          <FormInput.Label>How much is it?</FormInput.Label>
          <TransferForm.AmountInput>
            <FormInput.Select className="wrap" value={currency} readOnly>
              <option value={currency}>{currency}</option>
            </FormInput.Select>
            <FormInput.Input
              value={formData.amount}
              onChange={handleFormInputChange("amount")}
            />
          </TransferForm.AmountInput>
        </FormInput>
      </SpacedVerticalItems>
    </form>
  );
}
