import styled from "styled-components";

export default styled.section`
  margin-top: 22px;

  .actions {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      cursor: pointer;
      color: #529c4e;
      font-size: 0.7rem;
      font-weight: 600;
    }

    .seperator {
      display: inline-block;
      margin: 0 8px;
      color: #bbbbbb;
      font-size: 0.7rem;
      font-weight: normal;
    }
  }
`;
