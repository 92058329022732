import React from "react";
import { connect } from "react-redux";

import ProfileDropdown from "../blocks/ProfileDropdown";
import P from "../elements/P";

import { ReactComponent as LogoutIcon } from "../assets/img/logout-icon.svg";
import { ReactComponent as AccountIcon } from "../assets/img/account-icon.svg";

import { logout } from "../redux/auth/actions";
import ClickAway from "./ClickAway";
// import { signOut } from "../utils/helpers";

function UserDropDown(props) {
  const {
    user: { user }
  } = props;

  const [showDropdown, setShowDropdown] = React.useState(false);

  const toggleDropdown = e => setShowDropdown(!showDropdown);

  const closeDropdown = e => setShowDropdown(false);

  const onLogoutClicked = e => {
    props.logout();
    // signOut();
  };

  return (
    <ClickAway onClickAway={closeDropdown}>
      <ProfileDropdown>
        <ProfileDropdown.Info onClick={toggleDropdown}>
          <ProfileDropdown.Icon>
            <P small>{user.profile.first_name[0]}</P>
          </ProfileDropdown.Icon>
          <P small>{`${user.profile.first_name} ${user.profile.last_name}`}</P>
        </ProfileDropdown.Info>
        <ProfileDropdown.Dropdown show={showDropdown}>
          <ProfileDropdown.Item>
            <AccountIcon />
            <P small semiBold>
              Profile
            </P>
          </ProfileDropdown.Item>
          <ProfileDropdown.Seperator />
          <ProfileDropdown.Item onClick={onLogoutClicked}>
            <LogoutIcon />
            <P small semiBold>
              Logout
            </P>
          </ProfileDropdown.Item>
        </ProfileDropdown.Dropdown>
      </ProfileDropdown>
    </ClickAway>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(
  mapStateToProps,
  { logout }
)(UserDropDown);
