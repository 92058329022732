import styled from "styled-components";

export default styled.section`
  width: 100%;
  padding: 0px 12px;
  height: 260px;
  background-color: #f9f9fa;
  border: 1px solid #dfe3e8;
  border-radius: 5px;
  color: #bbbbbb;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }

  p.uploading-text {
    color: #172b4d;
  }

  & > button {
    margin-top: 11px;
  }

  & > .error-block {
    margin-top: 16px;
  }
`;
