import api from "../../utils/api";
// import storage from "../../utils/storage";
import { LOGIN_USER, LOGOUT_USER, SET_TOKEN, CLEAR_ERROR } from "./actionTypes";

export const login = formData => dispatch => {
  return dispatch({
    type: LOGIN_USER.default,
    payload: api.post("/auth/login", formData)
  }).then(res => {
    console.log({ res });
    // storage.set("auth", res.value.data);
    return res;
  });
};

export const setToken = token => dispatch => {
  // storage.set("auth", { access_token: token });
  dispatch({
    type: SET_TOKEN.fulfilled,
    payload: { token }
  });
};

export const logout = () => {
  // storage.clear();
  return dispatch =>
    dispatch({
      type: LOGOUT_USER.fulfilled
    });
};

export const clearError = () => {
  // storage.clear();
  return dispatch =>
    dispatch({
      type: CLEAR_ERROR.fulfilled
    });
};
