import styled, { css } from "styled-components";

export default styled.section`
  flex: 1;
  padding: ${props => props.padding || "20px"};
  /* height: 1px; */
  /* min-height: 100%; */
  &.padding {
    padding: 0px 20px;
  }
  &.no-padding {
    padding: 0px;
  }
  ${props =>
    props.fitChild &&
    css`
      height: auto;
      min-height: auto;
    `};
`;
