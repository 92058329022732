import React from "react";
import ChartLayout from "../blocks/ChartLayout";

function ChartWrapper({ chart }) {
  const options = {
    chart: {
      fontFamily: `"CharlieDisplay", sans-serif`,
      height: "300px",
      foreColor: "#97A4BA",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#67C462"],
    stroke: {
      width: [1.5]
    },
    grid: {
      show: true,
      borderColor: "#E0E7FF",
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    yaxis: {
      forceNiceScale: true,
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: "#E0E7FF"
      },
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "600"
        }
      }
    },
    xaxis: {
      type: "datetime",
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: "#E0E7FF"
      },
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "600"
        }
      }
    }
  };
  return <ChartLayout>{chart(options)}</ChartLayout>;
}

export default ChartWrapper;
