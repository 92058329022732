import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    & > p {
      display: none;
    }
  }
`;
