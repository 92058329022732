import styled from "styled-components";
import Body from "./Body";

const Main = styled.section`
  /* flex: 1; */
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;

  transition: margin 0.3s ease-in-out;

  /* TABLETS BELOW */
  @media only screen and (max-width: 1024px) {
    /* min-width: 100%; */
    min-width: 1024px;
    &.side-bar-open {
      margin-left: 240px;
    }
  }
`;

Main.Body = Body;

export default Main;
