import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
from {
    opacity: 0;
}

to {
    opacity: 1;
}
`;

export default styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  animation: ${fadeIn} 0.2s ease-in-out;
  min-height: 50px;
  min-width: 200px;
  z-index: 10;

  margin-top: 10px;

  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 12px 0 rgba(195, 203, 222, 0.59);

  ${props =>
    props.show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
