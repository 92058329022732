import mapKeys from "lodash/mapKeys";

import {
  FETCH_PAYMENTS,
  RESOLVE_PAYMENT,
  GENERATE_PAYMENT_LINK,
  CLEAR_PAYMENT_LINK
} from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  payments: null,
  meta: null,
  fetching: false,
  error: null,
  resolvePayment: { fetching: false, error: null },
  paymentlink: { fetching: false, error: null, link: null }
};

export default function payments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_PAYMENTS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_PAYMENTS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_PAYMENTS.fulfilled:
      return {
        ...state,
        fetching: false,
        meta: action.payload.data.paginator,
        payments: mapKeys(action.payload.data.data, _p => _p.code) // action.payload.data
      };

    case GENERATE_PAYMENT_LINK.pending:
      return {
        ...state,
        paymentlink: { fetching: true, error: null, link: null }
      };
    case GENERATE_PAYMENT_LINK.rejected:
      return {
        ...state,
        paymentlink: {
          fetching: false,
          error: parseError(action.payload),
          link: null
        }
      };
    case GENERATE_PAYMENT_LINK.fulfilled:
      return {
        ...state,
        paymentlink: {
          fetching: false,
          error: null,
          link: action.payload.data.data.hosted_url
        }
      };

    case CLEAR_PAYMENT_LINK: {
      return {
        ...state,
        paymentlink: INITIAL_STATE.paymentlink
      };
    }

    case RESOLVE_PAYMENT.pending:
      return {
        ...state,
        resolvePayment: {
          fetching: true,
          error: null
        }
      };
    case RESOLVE_PAYMENT.rejected:
      return {
        ...state,
        resolvePayment: {
          fetching: false,
          error: parseError(action.payload)
        }
      };
    case RESOLVE_PAYMENT.fulfilled:
      const payment = action.payload.data.data;
      return {
        ...state,
        // payments: mapKeys(action.payload.data.data, _p => _p.code), // action.payload.data
        payments: {
          ...state.payments,
          [payment.code]: payment
        },
        resolvePayment: {
          fetching: false,
          error: null
        }
      };

    default:
      return state;
  }
}
