import actionTypes from "../actionTypes";

export const GET_TRANSFER_FEE = actionTypes("GET_TRANSFER_FEE");
export const CREATE_TRANSFER = actionTypes("CREATE_TRANSFER");
export const INITIALIZE_BULK_TRANSFER = actionTypes("INITIALIZE_BULK_TRANSFER");
export const CLEAR_BULK_TRANSFER_ERROR = actionTypes(
  "CLEAR_BULK_TRANSFER_ERROR"
);
export const UPDATE_BULK_TRANSFER_ITEM = actionTypes(
  "UPDATE_BULK_TRANSFER_ITEM"
);
export const INITIALIZE_BULK_TRANSFER_PROGRESS =
  "INITIALIZE_BULK_TRANSFER_PROGRESS";
