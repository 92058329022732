import styled from "styled-components";

export default styled.section`
  width: 416px;
  margin: 48px 0;
  & > :first-child {
    width: 100%;
    /* height: 364px; */
    min-height: auto;
    height: auto;
  }
  form {
    height: 100%;
    padding: 50px 48px 68px;
    & > p {
      color: #42526e;
      margin-bottom: 26px;
    }
    & > button {
      margin-top: 33px;
    }
    & > :nth-child(2) {
      margin-bottom: 15px;
    }
  }
`;
