import styled from "styled-components";

export default styled.button`
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;

  svg {
    height: 18px;
    width: 16px;
  }

  & .tooltiptext {
    visibility: hidden;
    /* width: 120px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    /* left: 50%; */
    /* margin-left: -60px; */

    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;
