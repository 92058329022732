import api from "utils/api";

import { GET_PAYMENT_ANALYTICS, GET_TRANSFER_ANALYTICS } from "./actionTypes";

export const getPaymentAnalytics = (startDate, endDate) => (
  dispatch,
  getState
) => {
  const payload = api.get(
    `/businesses/${
      getState().business.activeBusiness
    }/analytics/transactions/volume?start=${startDate}&end=${endDate}`
  );
  return dispatch({ type: GET_PAYMENT_ANALYTICS.default, payload });
};

/* TODO:
  - change url for getTransferAnalytics when API endpoin is ready
*/

export const getTransferAnalytics = (startDate, endDate) => (
  dispatch,
  getState
) => {
  const payload = api.get(
    `/businesses/${
      getState().business.activeBusiness
    }/analytics/transactions/volume?start=${startDate}&end=${endDate}`
  );
  return dispatch({ type: GET_TRANSFER_ANALYTICS.default, payload });
};
