import React from "react";

import Message from "../blocks/Message";
import P from "../elements/P";

import { ReactComponent as CloseIcon } from "../assets/img/close-icon.svg";
import { ReactComponent as SuccessIcon } from "../assets/img/checkmark-green.svg";
import { ReactComponent as ErrorIcon } from "../assets/img/not-resolved.svg";

function Notification({ status, title, message, closeToast }) {
  const getStatusIcon = status => {
    switch (status) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      default:
        return null;
    }
  };
  return (
    <Message status={status}>
      <Message.Icon>{getStatusIcon(status)}</Message.Icon>
      <Message.Content>
        {title && (
          <P small semiBold>
            {title}
          </P>
        )}
        {message && <P small>{message}</P>}
      </Message.Content>
      <Message.Close>
        <button onClick={closeToast}>
          <CloseIcon />
        </button>
      </Message.Close>
    </Message>
  );
}

export default Notification;
