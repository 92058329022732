import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  padding: 0px ${({ padding }) => padding && "10px"};

  transition: padding 0.2s ease-in-out;

  & > p {
    flex: 1;
    margin-left: 12px;
  }

  & > svg {
    fill: #8993a4;
    use {
      fill: #42526e;
    }
  }
`;
