import mapKeys from "lodash/mapKeys";

import { FETCH_WALLETS } from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  wallets: null,
  fetching: false,
  error: null
};

export default function wallets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_WALLETS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_WALLETS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_WALLETS.fulfilled:
      return {
        ...state,
        fetching: false,
        wallets: mapKeys(action.payload.data, _w => _w.currency) // action.payload.data
      };

    default:
      return state;
  }
}
