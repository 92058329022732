import styled from "styled-components";
import Logo from "./Logo";
import Form from "./Form";

const BusinessForm = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    max-width: 500px;
    text-align: center;
    color: rgba(55, 53, 47, 0.4000000059604645);
  }
`;

BusinessForm.Logo = Logo;
BusinessForm.Form = Form;

export default BusinessForm;
