import mapKeys from "lodash/mapKeys";

import { FETCH_BUSINESS_BANKS } from "./actionTypes";

import parseError from "../../utils/parseError";
import { LOGOUT_USER } from "../auth/actionTypes";

const INITIAL_STATE = {
  banks: null,
  fetching: false,
  error: null
};

export default function businessBanks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_USER.fulfilled:
      return INITIAL_STATE;
    case FETCH_BUSINESS_BANKS.pending:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case FETCH_BUSINESS_BANKS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload)
      };
    case FETCH_BUSINESS_BANKS.fulfilled:
      return {
        ...state,
        fetching: false,
        banks: mapKeys(action.payload.data, _b => _b.id)
      };

    default:
      return state;
  }
}
