import styled from "styled-components";
import Body from "./Body";
import SwitchBox from "./SwitchBox";

const Topbar = styled.header`
  height: 72px;
`;

Topbar.Body = Body;
Topbar.SwitchBox = SwitchBox;

export default Topbar;
