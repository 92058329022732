import styled from "styled-components";

export default styled.textarea`
  background-color: rgba(242, 241, 238, 0.6);
  border-radius: 3px;
  border: 0.5px solid rgba(15, 15, 15, 0.1);
  width: 100%;
  min-height: 36px;
  outline: none;
  padding: 8px 15px;
  font-size: 0.7rem;
  font-family: "CharlieDisplay", sans-serif;

  transition: border 0.2s ease-in-out;

  &:focus {
    border: 0.5px solid #529c4e;
  }
`;
